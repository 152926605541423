import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'

const Admin = () => {
    const [qrModal, setQRModal] = useState(false);
    return (
        <Grid>
            <Grid display="flex" justifyContent="flex-end" marginBottom={2}>
                <Button variant="contained" onClick={() => setQRModal(true)}>
                    Add Admin User
                </Button>
            </Grid>


            *** UNDER DEVELOPMENT ***

            {/* ADD USER MODAL */}

            <Dialog fullWidth open={qrModal} onClose={() => setQRModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Invite User</DialogTitle>
                <DialogContent>
                    <TextField fullWidth placeholder='Email'></TextField>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='contained' onClick={() => { }}>
                        Send Invite
                    </Button>
                    <Button size='small' variant='outlined' onClick={() => setQRModal(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default Admin