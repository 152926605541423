import React, { useEffect, useState } from 'react'
import { useDashboard } from '../../Hooks/useDashboard'
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

export const Dashboard = () => {

    const [counts, setCounts] = useState({});
    const [reason, setReason] = useState([]);

    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
        },
        {
            name: '',
            data: [],
        },
    ]);

    const [options, setOptions] = useState({
        chart: {
            height: 350,
            type: 'area',
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            type: 'date',
            categories: [],
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm',
            },
        },
    });

    const { getCounts, getChartData, reasonToDelete } = useDashboard()

    useEffect(() => {
        (async () => {
            const temp = await getCounts()
            setCounts(temp.data.data)

            const chartData = await getChartData();

            const dates = chartData.data.map(item => item.date); // Extract dates
            const counts = chartData.data.map(item => parseInt(item.count, 10));

            setSeries([{ name: 'Count', data: counts }]);
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: { ...prevOptions.xaxis, categories: dates }
            }));

            const commonReasons = await reasonToDelete();
            setReason(commonReasons.data);
        })()
    }, [])


    return (
        <Container>
            <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total User Counts</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.user_counts}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card >
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total Product Counts</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.product_counts}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card >
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total Events</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.event_count}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card >
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box ></Box>
                                <Typography variant="h6">Total Archived Users</Typography>
                                <Typography variant="h2" color="textSecondary">{counts.archived_users_counts}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid marginTop={10} container gap={5}>
                <Box borderRadius={'10px'} minWidth={'45%'} bgcolor={'#b9d6f2'} padding={3}>
                    <Typography variant='h6'>
                        User acquisition in past 28 days
                    </Typography>
                    <ReactApexChart options={options} series={series} type="area" height={350} />
                </Box>
                <Box borderRadius={'10px'} minWidth={'45%'} bgcolor={'#b9d6f2'} padding={3}>
                    <Typography variant='h6'>
                        Common reasons users delete accounts
                    </Typography>
                    {
                        reason.map((ele) => {
                            return <ul style={{ listStyle: 'none', background: 'white', padding: '10px', margin: '5px', borderRadius: '10px' }}>
                                <li>{ele}</li>
                            </ul>
                        })
                    }
                </Box>
            </Grid>
        </Container>
    )
}
