import { API, USER_API } from '../Utils/API'
import { encryptData } from '../Utils/constant'
import { useLoader } from '../Utils/LoaderContext'

const useUsers = () => {

  const { showLoader, hideLoader } = useLoader()

  const getUsers = async () => {
    showLoader()
    const res = await API.post(USER_API.GET_LIST)
    hideLoader()
    return res;
  }

  const getUserProducts = async (id) => {
    showLoader()
    const res = await API.post(USER_API.GET_USER_PRODUCT, { id })
    hideLoader()
    return res;
  }

  const deleteUser = async (id) => {
    showLoader()
    const res = await API.post(USER_API.DELETE_USER, { email: encryptData(id), reason: 'DELETED FROM ADMIN PANLE' })
    hideLoader()
    return res
  }

  return { getUsers, deleteUser, getUserProducts }
}

export default useUsers