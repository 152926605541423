import { API, DASHBOARD_API } from '../Utils/API'
import { useLoader } from '../Utils/LoaderContext'

export const useDashboard = () => {

    const { showLoader, hideLoader } = useLoader()

    const getCounts = async () => {
        showLoader()
        const res = await API.post(DASHBOARD_API.GET_COUNTS);
        hideLoader();
        return res;
    }

    const getChartData = async () => {
        showLoader()
        const res = await API.post(DASHBOARD_API.GET_CHART_DATA);
        hideLoader();
        return res;
    }

    const reasonToDelete = async () => {
        showLoader()
        const res = await API.post(DASHBOARD_API.REASON_TO_DELETE);
        hideLoader();
        return res;
    }

    return { getCounts, getChartData, reasonToDelete }
}
