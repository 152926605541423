import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import moment from 'moment'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';

const CategoryMenu = () => {
    return (
        <Grid className="product__page">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {['Category', 'Is Active', 'Created At', 'Action'].map((header) => (
                                <TableCell key={header} style={{ fontWeight: 'bold' }} align="left">{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[].map((row) => (
                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">{row.batch_number}</TableCell>
                                <TableCell align="left">{row.mrp}</TableCell>
                                <TableCell align="left">{row.version}</TableCell>
                                <TableCell align="left">{moment(row.createdAt).format('LLL')}</TableCell>
                                {/* <TableCell align="left">
                                    <IconButton onClick={() => { setDeleteConfirm(true); setDeleteId(row.id); }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default CategoryMenu