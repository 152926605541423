import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { API, EVENT_API } from '../../Utils/API';
import { decryptedBody } from '../../Utils/constant';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLoader } from '../../Utils/LoaderContext';


const List = () => {
    const [event, setEvents] = useState([]);

    const location = useLocation()
    const { showLoader, hideLoader } = useLoader()


    useEffect(() => {
        fetchList();
    }, [])

    const getParsedJSON = (json) => {
        let jsonString = json.replace(/\\/g, '"');
        return JSON.parse(jsonString);
    }

    const fetchList = async () => {
        showLoader();
        const res = await API.post(EVENT_API.GET_LIST, { data: location.state?.email });
        hideLoader();
        if (res.data.status === 200) {
            const temp = decryptedBody(res.data.data)
            const data = temp.map((ele) => {
                const obj = {
                    ...ele,
                    ...getParsedJSON(ele.data),
                }

                delete obj.data

                return obj
            })
            setEvents(data)
        }
    }

    return (
        <>
            <Grid className="product__page">
                <Box padding={'10px'} display={'flex'} alignItems={'center'} gap={'2px'} sx={{ backgroundColor: 'lightgrey' }}>
                    <AccountCircleIcon />
                    <Typography variant='h6'>
                        {location.state?.firstname + ' ' + location.state?.lastname}
                    </Typography>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {['IP', 'MAC ID', 'Product Type', 'Event Type', 'longitude', 'latitude', 'Date (Device Recorded)', 'Address', "Created At (Server Recorded)", "Action"].map((header) => (
                                    <TableCell key={header} style={{ fontWeight: 'bold' }} align="left">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                location.state ?
                                    event.map((row) => (
                                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">{row.ip}</TableCell>
                                            <TableCell align="left">{row.mac_id}</TableCell>
                                            <TableCell align="left">{row.product_type}</TableCell>
                                            <TableCell align="left">{row.event_type}</TableCell>
                                            <TableCell align="left">{row.longitude}</TableCell>
                                            <TableCell align="left">{row.latitude}</TableCell>
                                            <TableCell align="left">{moment(row.date).format('LLL')}</TableCell>
                                            <TableCell align="left">{row.address}</TableCell>
                                            <TableCell align="left">{moment(row.createdAt).format('LLL')}</TableCell>
                                            <TableCell align="left">
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        Select user from user list
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default List