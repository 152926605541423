import { API, HELP_API } from "../Utils/API"
import { useLoader } from "../Utils/LoaderContext"

export const useHelp = () => {

    const { showLoader, hideLoader } = useLoader()
    const getIssues = async () => {
        showLoader()
        const res = await API.post(HELP_API.GET_LIST);
        hideLoader();
        return res
    }

    return { getIssues }
}
