import DeleteIcon from '@mui/icons-material/Delete';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import useProduct from '../../Hooks/useProduct';
import { baseURL } from '../../Utils/API';
import { decryptedBody } from '../../Utils/constant';
import { countryCode } from '../../Utils/CountryCode';
import { Label } from '@mui/icons-material';

const ProductList = () => {

  const [products, setProducts] = useState([]);
  const [addProduct, setAddProduct] = useState(false);

  const [productData, setProductData] = useState({ country_code: "+91" });

  const { getProducts, addProduct: addNewProduct, deleteProduct } = useProduct();

  const [qrURL, setQrURL] = useState('');
  const [qrID, setQrID] = useState('');
  const [qrMacId, setQrMacID] = useState('');
  const [qrModal, setQRModal] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const ref = useRef();


  const handleOpenDialog = () => setAddProduct(true);
  const handleCloseDialog = () => setAddProduct(false);

  const productTypes = ['SalvusBW', 'SalvusBP', 'SalvusBC'];

  const productCategories = ['Electronics'];

  useEffect(() => {
    fetchProducts();
  }, [])

  const fetchProducts = async () => {
    const prod = await getProducts();
    const temp = decryptedBody(prod.data.data)
    setProducts(temp)
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setProductData({ ...productData, [name]: value })
  }

  const saveProduct = async () => {
    try {
      await addNewProduct(productData);
      handleCloseDialog()
      fetchProducts()
      toast.success('🎉 Product added')
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }
  const printQR = () => {
    const printContents = ref.current.innerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(`
        <html>
            <head>
                <title>Print QR Code</title>
                <style>
                    @media print {
                        @page {
                            size: 450px 450px; /* Set paper size to 200x200 pixels */
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                </style>
            </head>
            <body>
            <div style="width: 308px; height:308px; padding:0px;">
            ${printContents}
            <p style="text-align:center;">${qrID}</p>
            <p style="text-align:center;">${qrMacId}</p>
            </div>
            </body>
        </html>
    `);
    printWindow.document.close();

    printWindow.print();
    // printWindow.close();
  };


  const deleteProducts = async (id) => {
    await deleteProduct(id)
    fetchProducts();
    setDeleteConfirm(false)
  }

  return (
    <Grid>
      <Grid display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button variant="contained" onClick={handleOpenDialog}>
          Add Product
        </Button>
      </Grid>

      <Grid className="product__page">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {['Product type', 'MAC ID', 'QR ID', 'Category', 'Color', 'Batch Number', 'Manufacturing Date', 'MRP', 'Version', 'QR', 'Barcode', 'Created At', 'Action'].map((header) => (
                  <TableCell key={header} style={{ fontWeight: 'bold' }} align="left">{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.mac_id}</TableCell>
                  <TableCell align="left">{row.uniqueId}</TableCell>
                  <TableCell align="left">{row.category}</TableCell>
                  <TableCell align="left">{row.color}</TableCell>
                  <TableCell align="left">{row.batch_number}</TableCell>
                  <TableCell align="left">{moment(row.manufacturing_date).format('L')}</TableCell>
                  <TableCell align="left">{row.mrp}</TableCell>
                  <TableCell align="left">{row.version}</TableCell>
                  <TableCell align="left">
                    <IconButton onClick={() => {
                      setQrURL(`${baseURL}/${row.qrPath}`)
                      setQrID(row.uniqueId)
                      setQrMacID(row.mac_id)
                      setQRModal(true)
                    }}
                    >
                      <img width={40} alt="" src={`${baseURL}/${row.qrPath}`} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton>
                      <img width={40} alt="" src={`${baseURL}/${row.barcodePath}`} />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">{moment(row.createdAt).format('LLL')}</TableCell>
                  <TableCell align="left">
                    <IconButton onClick={() => { setDeleteConfirm(true); setDeleteId(row.id); }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* ADD PRODUCT */}
      <Dialog open={addProduct} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Add new product</DialogTitle>
        <DialogContent>
          <Grid>
            <Grid padding={2} display="flex" gap={3}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select onChange={handleChange} labelId="type-label" label="Type" name='type'>
                  {productTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select onChange={handleChange} name='category' labelId="category-label" label="Category">
                  {productCategories.map((category) => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid padding={2} display="flex" gap={3} width="100%">
              <TextField onChange={handleChange} name='mac_id' fullWidth id="mac-id" label="MAC ID" variant="outlined" />
            </Grid>

            <Grid padding={2} display="flex" gap={3}>
              <TextField onChange={handleChange} name='color' fullWidth id="color" label="Color" variant="outlined" />
              <TextField onChange={handleChange} name='version' fullWidth id="version" label="Version" variant="outlined" />
            </Grid>

            <Grid padding={2} display="flex" gap={3}>
              <TextField onChange={handleChange} name='batch_number' fullWidth id="batch-number" label="Batch Number" variant="outlined" />

              <FormControl fullWidth>
                <InputLabel id="country-label">Country Code</InputLabel>
                <Select value={"+91"} disabled onChange={handleChange} name='country_code' labelId="country-label" label="Country Code">
                  {countryCode.map(({ code, country, flag }, i) => (
                    <MenuItem key={i} value={code}>{`${code} ${country} ${flag}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid padding={2} display="flex" gap={3}>
              <TextField onChange={handleChange} name='mrp' id="mrp" label="MRP" variant="outlined" />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  onChange={(newValue) => {
                    setProductData({ ...productData, manufacturing_date: moment(newValue).format() })
                  }}
                  disableFuture
                  name='manufacturing_date'
                  label="Manufacturing Date"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={saveProduct}>Save</Button>
          <Button variant='outlined' onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>


      {/* QR CODE */}

      <Dialog open={qrModal} onClose={() => setQRModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">QR Code</DialogTitle>
        <DialogContent style={{ paddingBottom: 0 }} ref={ref}>
          <img src={qrURL} />
        </DialogContent>
        <Box display={'flex'} gap={'5px'} justifyContent={'center'}>
          <Typography align='center'>{qrID}</Typography> |
          <Typography align='center'>{qrMacId}</Typography>
        </Box>
        <DialogActions>
          <Button size='small' variant='contained' onClick={printQR}>
            <LocalPrintshopIcon style={{ width: '20px', marginRight: '5px' }} />
            Print
          </Button>
          <Button size='small' variant='outlined' onClick={() => setQRModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* DELETE CONFIRM */}
      <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">QR Code</DialogTitle>
        <DialogContent>
          This action can't be reverted.
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button size='small' variant='contained' onClick={() => deleteProducts(deleteId)}>
            <DeleteIcon style={{ width: '20px', marginRight: '5px' }} />
            Delete
          </Button>
          <Button size='small' variant='outlined' onClick={() => setDeleteConfirm(false)}>Close</Button>
        </DialogActions>
      </Dialog>

    </Grid>
  )
}

export default ProductList