import axios from "axios";

let baseURL;
const env = "stage"
if (env === 'production') {
    baseURL = 'https://api.salvussequr.com';
} else if (env === 'stage') {
    baseURL = 'https://stage.salvussequr.com';
} else if (env === 'development') {
    baseURL = 'http://localhost:5000';
} else {
    baseURL = 'https://api.salvussequr.com';
}

export const API = axios.create({
    baseURL: baseURL,
    headers: {
        key: '7f8c9d6a1b2e3c4d5e6f7a8b9c0d1e2f3a4b5c6d7e8f9a0b1c2d3e4f5a6b7c8'
    }
});

export { baseURL };

export const PRODUCT_API = {
    GET_LIST: `${baseURL}/api/v1/get-products`,
    ADD_PRODUCT: `${baseURL}/api/v2/addProduct`,
    DELETE_PRODUCT: `${baseURL}/api/v1/delete-product`
}

export const USER_API = {
    GET_LIST: `${baseURL}/api/v1/get-users`,
    DELETE_USER: `${baseURL}/api/v1/delete-user`,
    GET_USER_PRODUCT: `${baseURL}/api/v1/get-user-product`,
}

export const HELP_API = {
    GET_LIST: `${baseURL}/api/v1/get-user-issue`,
    GET_ISSUE_CATEGORY_LIST: `${baseURL}/api/v1/get-help-category`,
    CREATE_ISSUE_CATEGORY: `${baseURL}/api/v1/add-help-category`,
}

export const EVENT_API = {
    GET_LIST: `${baseURL}/api/v1/get-event-records-by-email`
}

export const DASHBOARD_API = {
    GET_COUNTS: `${baseURL}/api/v1/get-dashboard-counts`,
    GET_CHART_DATA: `${baseURL}/api/v1/get-user-chart-data`,
    REASON_TO_DELETE: `${baseURL}/api/v1/common-reason-user-delete-app`
}