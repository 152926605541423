import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useUsers from '../../Hooks/useUsers';
import { decryptData, decryptedBody, DESCRYPTION_TYPE, encryptData } from '../../Utils/constant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import HistoryIcon from '@mui/icons-material/History';

const AppUsers = () => {

    const [users, setUsers] = useState([]);
    const [userProducts, setUserProducts] = useState({});

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [userProductModal, setUserProductModal] = useState(false);

    const [userHistoryModal, setUserHistoryModal] = useState(false);
    const navigate = useNavigate();

    const { getUsers, deleteUser, getUserProducts } = useUsers()

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = async () => {
        const temp = await getUsers();
        const res = decryptedBody(temp.data.data)
        setUsers(res)
    }

    const deleteProducts = async (id) => {
        await deleteUser(id)
        fetchUsers();
        setDeleteConfirm(false)
    }

    const fetchUserProduct = async (id) => {
        const temp = await getUserProducts(id);
        if (temp.data.data) {
            const obj = temp.data.data;
            const res = {
                mac_id: decryptData(obj.mac_id, DESCRYPTION_TYPE.STRING),
                version: decryptData(obj.version, DESCRYPTION_TYPE.STRING),
                color: decryptData(obj.color, DESCRYPTION_TYPE.STRING),
                type: decryptData(obj.type, DESCRYPTION_TYPE.STRING),
            }
            setUserProducts(res);
        }
    }

    const fetchUserHistory = () => {

    }

    return (
        <Grid>
            <Grid display="flex" justifyContent="flex-end" marginBottom={2}>
                <Button variant="contained">
                    Add User
                </Button>
            </Grid>

            <Grid className="product__page">
                <TableContainer component={Paper}>
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {['Fristname', 'Last name', 'Email', 'Phone', 'Verified', 'Created At', 'Action'].map((header) => (
                                    <TableCell key={header} style={{ fontWeight: 'bold' }} align="left">{header}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">{row.firstname}</TableCell>
                                    <TableCell align="left">{row.lastname}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">{row.phone}</TableCell>
                                    <TableCell align="left">{row.isVerify ?
                                        <Tooltip title="Done">
                                            <DoneIcon />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Pending">
                                            <HourglassEmptyIcon />
                                        </Tooltip>
                                    }</TableCell>
                                    <TableCell align="left">{moment(row.createdAt).format('LLL')}</TableCell>
                                    <TableCell align="left">
                                        <IconButton onClick={() => {
                                            const email = encryptData(row.email);
                                            navigate(`/events`, { state: { email, firstname: row.firstname, lastname: row.lastname } });
                                        }}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => { fetchUserProduct(row.id); setUserProductModal(true); }}>
                                            <QrCodeScannerIcon />
                                        </IconButton>

                                        <IconButton onClick={() => { fetchUserHistory(row.id); setUserHistoryModal(true); }}>
                                            <HistoryIcon />
                                        </IconButton>

                                        <IconButton onClick={() => { setDeleteConfirm(true); setDeleteId(row.email); }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>



            {/* DELETE CONFIRM */}
            <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
                <DialogContent>
                    This action can't be reverted.
                    Are you sure you want to delete this user?
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='contained' onClick={() => deleteProducts(deleteId)}>
                        <DeleteIcon style={{ width: '20px', marginRight: '5px' }} />
                        Delete
                    </Button>
                    <Button size='small' variant='outlined' onClick={() => setDeleteConfirm(false)}>Close</Button>
                </DialogActions>
            </Dialog>


            {/* USER PRODUCT LIST */}
            <Dialog open={userProductModal} minWidth={950} onClose={() => setUserProductModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">User products</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {['Mac ID', 'Color', 'Type', 'Version'].map((header) => (
                                        <TableCell key={header} style={{ fontWeight: 'bold' }} align="left">{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">{userProducts.mac_id}</TableCell>
                                    <TableCell align="left">{userProducts.color}</TableCell>
                                    <TableCell align="left">{userProducts.type}</TableCell>
                                    <TableCell align="left">{userProducts.version}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='outlined' onClick={() => setUserProductModal(false)}>Close</Button>
                </DialogActions>
            </Dialog>



            {/* USER HISTORY LIST */}
            <Dialog open={userHistoryModal} fullWidth onClose={() => setUserHistoryModal(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">User history</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {['Deleted On', 'Reason'].map((header) => (
                                        <TableCell key={header} style={{ fontWeight: 'bold' }} align="left">{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                ** UNDER DEVELOPMENT **

                                {/* <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">{userProducts.mac_id}</TableCell>
                                    <TableCell align="left">{userProducts.color}</TableCell>
                                    <TableCell align="left">{userProducts.type}</TableCell>
                                    <TableCell align="left">{userProducts.version}</TableCell>
                                </TableRow> */}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='outlined' onClick={() => setUserHistoryModal(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default AppUsers